import {BREAKPOINTS} from "@/utilities/breakpoints";
import {DependencyList, useEffect, useRef, useState} from "react";

type Dimensions = {
	width: number;
	height: number;
};

export const useViewportDimensions = () => {
	const [dimensions, setDimensions] = useState<Dimensions>({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		window.addEventListener("resize", () => {
			setDimensions({width: window.innerWidth, height: window.innerHeight});
		});
	}, []);

	return dimensions;
};

type Operator = "<" | "<=" | ">" | ">=";
export type Breakpoint = `${Operator}${number}`;
export type BreakpointCallbacks = Record<Breakpoint, (width: number, height: number) => void>;

/**
 * Provide an object of callbacks keyed by breakpoint width.
 */
export const useOnResize = (breakpoints: BreakpointCallbacks, depsArray: DependencyList, continuous?: boolean) => {
	let isFirst = useRef(true);
	let lastWidth = useRef(typeof window !== "undefined" ? window.innerWidth : 0);

	const breakpointsByOperator = Object.keys(breakpoints).reduce((acc: string[][], value: string) => {
		const operator = value.replace(/[0-9]+/, "");
		const width = value.replace(/[<>=]+/, "");
		acc.push([operator, width]);
		return acc;
	}, []);

	function onResize() {
		const width = window.innerWidth;
		const height = window.innerHeight;

		Object.entries(breakpoints).forEach(([breakpoint, callback], i) => {
			const breakpointCondition = breakpointsByOperator[i][0];
			const breakpointWidth = breakpointsByOperator[i][1];
			switch (breakpointCondition) {
				case "<":
					if (
						width < Number(breakpointWidth) &&
						(isFirst.current || continuous || lastWidth.current >= Number(breakpointWidth))
					) {
						callback(width, height);
					}
					break;
				case "<=":
					if (
						width <= Number(breakpointWidth) &&
						(isFirst.current || continuous || lastWidth.current > Number(breakpointWidth))
					) {
						callback(width, height);
					}
					break;
				case ">":
					if (
						width > Number(breakpointWidth) &&
						(isFirst.current || continuous || lastWidth.current <= Number(breakpointWidth))
					) {
						callback(width, height);
					}
					break;
				case ">=":
					if (
						width >= Number(breakpointWidth) &&
						(isFirst.current || continuous || lastWidth.current < Number(breakpointWidth))
					) {
						callback(width, height);
					}
					break;
			}
		});
		isFirst.current = false;
		lastWidth.current = width;
	}

	useEffect(() => {
		onResize();
	}, []);

	useEffect(() => {
		window.addEventListener("resize", onResize);

		return () => {
			window.removeEventListener("resize", onResize);
		};
	}, depsArray);
};

export const useBreakpoint = (bp: keyof typeof BREAKPOINTS, defaultValue = false) => {
	const [match, setMatch] = useState(defaultValue);

	useOnResize(
		{
			[`>=${BREAKPOINTS[bp]}`]: () => setMatch(true),
			[`<${BREAKPOINTS[bp]}`]: () => setMatch(false),
		},
		[],
	);

	return match;
};
