import(/* webpackMode: "eager" */ "/vercel/path0/site/components/layout/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/site/components/layout/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionGeneric"] */ "/vercel/path0/site/components/shared/AccordionGeneric/AccordionGeneric.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/images/icons/caret-down.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/images/logos/logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
