"use client";
import { useState } from "react";
import styles from "./AccordionGeneric.module.scss";
import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { DummyRichText } from "@/types/data";

interface AccordionGenericProps {
	name: string /** Required for accessibility. "Expand/Collapse {name}" */;
	header: DummyRichText;
	body: DummyRichText;
	defaultExpanded?: boolean;
	as?: React.ElementType;
	headerAs?: React.ElementType;
	className?: string;
	headerClassName?: string;
	expandedClassName?: string;
}

export const AccordionGeneric = ({
	name,
	header,
	body,
	defaultExpanded = false,
	as: Element = "div",
	headerAs: HeaderElement = "div",
	className,
	headerClassName,
	expandedClassName,
}: AccordionGenericProps) => {
	const [isExpanded, setIsExpanded] = useState(defaultExpanded);

	return (
		<Element className={clsx(styles["accordion"], className, isExpanded && expandedClassName)}>
			<HeaderElement className={styles["accordion__header"]}>
				<button
					type="button"
					aria-expanded={isExpanded}
					aria-label={`${isExpanded ? "Collapse" : "Expand"} ${name}`}
					onClick={() => setIsExpanded(!isExpanded)}
					className={clsx(styles["accordion__button"], headerClassName)}
				>
					{header}
				</button>
			</HeaderElement>
			<AnimatePresence>
				{isExpanded && (
					<motion.div
						initial={{ height: 0 }}
						animate={{ height: "auto" }}
						exit={{ height: 0 }}
						className={styles["accordion__body"]}
					>
						{body}
					</motion.div>
				)}
			</AnimatePresence>
		</Element>
	);
};
